@import url('https://db.onlinewebfonts.com/c/b1e31a1d280949f471da840203e74668?family=Kabob+Regular');

@font-face {
    font-family: 'kabob';
    font-style: normal;
    font-weight: normal;
    src: local('Kabob'), url('../Kabob.ttf') format('truetype');
}

* {
    text-decoration: none;
    font-family: 'kabob';
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #000;
}

/* navbar-china website */
.navbar-logo {
    height: 55PX;
    transition: height 0.3s ease;
    width: 100%; /* Makes the image responsive to its container */
    max-width: 250px; /* Set the maximum width as needed */
    object-fit: contain; /* Ensure the image scales without distortion */
    display: block; /* Prevent unwanted inline spacing */
    margin: 0 auto; /* Optional: Center the logo within its container */
}

@media (max-width: 768px) {
    .navbar-logo {
        max-height: 50px; /* Adjust for smaller screens */
    }
}

@media (max-width: 480px) {
    .navbar-logo {
        max-height: 30px; /* Adjust for even smaller screens */
    }
}


.nav-cn-bg-main {
    width: 100%;
    position: fixed;
    z-index: 5;
    color: #ffffffcc;
}

.navbar-cn-bg-main {
    width: 100%;
    height: 120px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
}

.navbar-cn-bg-main-ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 86%;
    height: 100%;
}

.BgColour {
    background-color: rgba(49, 48, 48, 0.692);
}

.menu-nav-cn {
    font-weight: bolder;
    letter-spacing: 0.1666666667em;
    font-size: 1rem;
    line-height: 2;
    text-transform: uppercase;
    position: relative;
    border: none;
    color: inherit;
}




/* Dropdown container */
.custom-dropdown {
    display: inline-block;
    position: relative;
}

.custom-dropdown .dropdown-toggle {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.custom-dropdown .dropdown-toggle:hover,
.custom-dropdown .dropdown-toggle:focus {
    background-color: transparent;
}

.custom-dropdown .dropdown-menu {
    min-width: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px 0;
    border: none;
}

.custom-dropdown .dropdown-item {
    padding: 8px 20px;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s ease;
}

.custom-dropdown .dropdown-item:hover {
    background-color: #f1f1f1;
    color: #000;
}

/* contact overlay form */
.overlay {
    z-index: 9999;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    overflow-y: hidden;
    transition: 0.5s;
}

.close-form-nys-cn {
    padding: 20px;
    background-color: #002147;
}

.overlay-content {
    position: relative;
    width: 100%;
    text-align: center;
}

.over-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5%;
    background-color: #fff;
}

.over-header a {
    font-size: 40px;
    color: #002147;
}

.overlay .closebtn {
    text-decoration: none;
    position: absolute;
}

@media screen and (max-height: 450px) {
    .overlay {
        overflow-y: auto;
    }

    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}

/* Drawer-Menu */
.overlay-menu {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: hidden;
    transition: 0.5s;
}

.overlay-open-menu {
    height: 50%;
}

.overlay-content-menu {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay-menu a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay-menu a:hover,
.overlay-menu a:focus {
    color: #f1f1f1;
}

.closebtn-menu {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

@media screen and (max-height: 450px) {
    .overlay-menu {
        overflow-y: auto;
    }

    .overlay-menu a {
        font-size: 20px;
    }

    .closebtn-menu {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}


/* FORM */
:root {
    --ligthgreen: #f5f5f5;
    --darkgreen: #f8c723;
}

.form-body-cn {
    font-family: "kabob";
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 95vh;

    .container {
        background-color: var(--ligthgreen);
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        width: 1050px;
        height: 750px;
        max-width: 100%;
        box-shadow: 5px 5px 30px 0px rgba(128, 128, 128, 0.062);
        padding: 50px 0px;
        text-align: center;
        transition: transform 0.6s ease-in-out;
    }

    .container p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

    .forgetpass {
        margin-top: 20px;
    }

    a {
        color: black;
        text-decoration: none;
        font-size: 20px;
    }

    button {
        font-size: 12px;
        padding: 10px;
        width: 95px;
        height: 40px;
        border-radius: 15px;
        background: #000;
        color: white;
        margin: 20px 0px;
        border: none;
        cursor: pointer;
    }

    .button {
        font-size: 12px;
        padding: 10px;
        width: 95px;
        height: 40px;
        border-radius: 15px;
        background: #000;
        color: white;
        margin: 20px 0px;
        border: none;
        cursor: pointer;
    }

    h1 {
        font-size: 2.25rem;
    }

    .container button.hidden {
        background-color: transparent;
        color: black;
    }

    .icons .icon-form {
        margin: 10px 3px 20px 3px;
    }

    .container form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding: 0 40px;
    }

    input {
        width: 100%;
        height: 40px;
        padding: 10px;
        border-radius: 15px;
        font-size: 14px;
        margin: 10px 0px;
        outline: none;
        border: 2px solid white;
    }

    .passdiv {
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        border-radius: 15px;
        margin: 10px 0;
    }

    .passdiv .show-hide {
        padding-right: 15px;
        cursor: pointer;
        transition: 0.2s ease;
    }

    .form_container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }

    .sign_in {
        left: 0;
        width: 50%;
        z-index: 1;
    }


    .container.active .sign_in {
        transform: translateX(100%);
        opacity: 0;
    }

    .sign_up {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
    }

    .container.active .sign_up {
        transform: translateX(100%);
        opacity: 1;
        z-index: 1;
        animation: move 0.6s;
    }

    .text {
        display: none;
        padding: 0px 2px;
        color: brown;
    }

    @keyframes move {

        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }

        50%,
        100% {
            opacity: 1;
            z-index: 1;
        }
    }

    .toggle_container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: all 0.6s ease-in-out;
        border-radius: 75px 0 0 75px;
        z-index: 5;
    }

    .container.active .toggle_container {
        transform: translateX(-100%);
        border-radius: 0 75px 75px 0;
    }

    .toggle {
        background-color: var(--darkgreen);
        height: 100%;
        color: #fff;
        position: relative;
        left: -100%;
        width: 200%;
        transform: translateX(0);
        transition: all 0.6s ease-in-out;
    }

    .toggle p {
        padding-top: 20px;
    }

    .container.active .toggle {
        transform: translateX(50%);
    }


    .toggle_panel {
        position: absolute;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
        text-align: center;
        top: 0;
        transform: translateX(0);
        transition: all 0.6s ease-in-out;
    }

    .toggle_left {
        transform: translateX(-200%);
    }

    .toggle_left p {
        font-size: 1rem;
        width: 70%;
    }

    .container.active .toggle_left {
        transform: translateX(0);
    }

    .toggle_right {
        right: 0;
        transform: translateX(0);
    }

    .toggle_right p {
        font-size: 1rem;
        width: 70%;
    }

    .container.active .toggle_right {
        transform: translateX(200%);
    }

    @media (max-width: 868px) {
        .container {
            width: 90%;
            height: 470px;
        }
    }


}

/* FOOTER */

.footer-cn {
    ul {
        margin: 0px;
        padding: 0px;
    }

    .footer-section {
        background: #151414;
        position: relative;
    }

    .footer-cta {
        border-bottom: 1px solid #373636;
    }

    .single-cta i {
        color: #f8c723;
        font-size: 30px;
        float: left;
        margin-top: 8px;
    }

    .cta-text {
        padding-left: 15px;
        display: inline-block;
    }

    .cta-text h4 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .cta-text span {
        color: #cfcece;
        font-size: 15px;
    }

    .footer-content {
        position: relative;
        z-index: 2;
    }

    .footer-pattern img {
        position: absolute;
        top: 0;
        left: 0;
        height: 330px;
        background-size: cover;
        background-position: 100% 100%;
    }

    .footer-logo {
        /* margin-bottom: 30px; */
    }

    .footer-logo img {
        /* max-width: 200px; */
    }

    .footer-text p {
        margin-bottom: 14px;
        font-size: 14px;
        color: #cfcece;
        line-height: 28px;
    }

    .footer-social-icon span {
        color: #fff;
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .footer-social-icon a {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        margin-right: 15px;
    }

    .facebook-bg {
        background: #3B5998;
    }

    .twitter-bg {
        background: #55ACEE;
    }

    .google-bg {
        background: #DD4B39;
    }

    .footer-widget-heading h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
        position: relative;
    }

    .footer-widget-heading h3::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -15px;
        height: 2px;
        width: 50px;
        background: #f8c723;
    }

    .footer-widget ul li {
        display: inline-block;
        float: left;
        width: 50%;
        margin-bottom: 12px;
    }

    .footer-widget ul li a:hover {
        color: #f8c723;
    }

    .footer-widget ul li a {
        color: #cfcece;
        text-transform: capitalize;
    }

    .subscribe-form {
        position: relative;
        overflow: hidden;
    }

    .subscribe-form input {
        width: 100%;
        padding: 14px 28px;
        background: #2E2E2E;
        border: 1px solid #2E2E2E;
        color: #fff;
    }

    .subscribe-form button {
        position: absolute;
        right: 0;
        background: #f8c723;
        padding: 13px 20px;
        border: 1px solid #f8c723;
        top: 0;
    }

    .subscribe-form button i {
        color: #fff;
        font-size: 22px;
        transform: rotate(-6deg);
    }

    .copyright-area {
        background: #202020;
        padding: 8px 0;
    }

    .copyright-text p {
        margin: 0;
        font-size: 14px;
        color: #878787;
    }

    .copyright-text p a {
        color: #f8c723;
    }

    .footer-menu li {
        display: inline-block;
        margin-left: 20px;
    }

    .footer-menu li:hover a {
        color: #f8c723;
    }

    .footer-menu li a {
        font-size: 14px;
        color: #878787;
    }
}
* {
    box-sizing: border-box;
    font-family: "kabob";
}

a {
    text-decoration: none;
    color: #000;
}


#google_translate_element .goog-te-gadget {
    display: none !important;
}

/*Our Mission Text*/
.mini-block-statement {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 5vw;
    display: flex;
    position: relative;
    overflow: hidden;
}

.heading-34 {
    float: none;
    color: #000000;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "kabob";
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 100%;
    display: inline-block;
}

.mini-block-statement h2 {
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    font-family: "kabob";
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 100%;
    display: flex;
}

.text-block-60 {
    float: right;
    color: #000000;
    padding-top: 2vw;
    padding-bottom: 0;
    position: relative;
}

/* LANDING VIDEO */
#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.content {
    position: "fixed";
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
}

#myBtn {
    width: 200px;
    font-size: 18px;
    padding: 10px;
    border: none;
    background: #000;
    color: #fff;
    cursor: pointer;
}

#myBtn:hover {
    background: #ddd;
    color: black;
}

/* Split screen hover to change */
.icon-hidden {
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.hover-container:hover .icon-hidden {
    opacity: 1;
    transform: translateX(0);
}

.chinasite-landing-page {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.split {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: width 0.5s ease;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.split.left {
    background-image: url('../Z-IMAGE//Landing-banner-\(technology\).png');
    color: white;
}

.split.right {
    background-image: url('../Z-IMAGE/Landing-banner-\(Supply-Chain-Management\).png');
    color: white;
}

.split.left:hover,
.split.right:hover {
    cursor: pointer;
    transition: 1s;

}

.chinasite-landing-page.hover-left .left {
    width: 55%;
    transition: 1s;
}

.chinasite-landing-page.hover-left .right {
    width: 45%;
    transition: 1s;
}

.chinasite-landing-page.hover-right .right {
    width: 55%;
    transition: 1s;
}

.chinasite-landing-page.hover-right .left {
    width: 45%;
    transition: 1s;
}

.split .hoverrrr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(255, 255, 255);
    text-align: center;
}

@media (max-width: 576px) {
    .split h6 {
        text-transform: uppercase;
        font-size: .75em;
        font-weight: bolder;
        color: #ffffff;
    }

    .split span {
        text-transform: uppercase;
        margin-bottom: 7%;
        font-size: .5em;
        transition: 2s;
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .split h6 {
        text-transform: uppercase;
        font-size: .75em;
        font-weight: bolder;
        color: #ffffff;
    }

    .split span {
        text-transform: uppercase;
        margin-bottom: 7%;
        font-size: .5em;
        transition: 2s;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1050px) {
    .split h6 {
        text-transform: uppercase;
        font-size: .85em;
        font-weight: bolder;
        color: #ffffff;
    }

    .split span {
        text-transform: uppercase;
        margin-bottom: 7%;
        font-size: .5em;
        transition: 2s;
    }
}

@media only screen and (min-width: 1051px) and (max-width: 1200px) {
    .split h6 {
        text-transform: uppercase;
        font-size: 1.15em;
        font-weight: bolder;
        color: #ffffff;
    }

    .split span {
        text-transform: uppercase;
        margin-bottom: 7%;
        font-size: .75em;
        transition: 2s;
    }
}

@media only screen and (min-width: 1301px) {
    .split h6 {
        text-transform: uppercase;
        font-size: 1.5em;
        font-weight: bolder;
        color: #ffffff;
    }

    .split span {
        text-transform: uppercase;
        margin-bottom: 7%;
        font-size: 1em;
        transition: 2s;
    }
}

/* Parallax effect */
.split.left {
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    transition: 1s;
}

.split.right {
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    transition: 1s;
}

/* .split span {
    text-transform: uppercase;
    margin-bottom: 10%;
    font-size: 1em;
    transition: 1s;
} */

.split span:hover {
    transition: 1s;
    transform: scale(1.20);
}

.custom-btn {
    transition: 1s;
    border: 3px solid #22c0de;
    color: #22c0de;
    padding: 10px 20px;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.custom-btn:hover {
    transition: 1s;
    background-color: #22c0de;
    color: black;
    transform: scale(1.10);
}

.video-text-animation-cn {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    line-height: 5.9rem;
}

.char {
    transform: translateY(115px);
    transition: transform .5s;
}



.TECH-H3-CN-NYS {
    text-transform: uppercase;

}





/* ems */
:root {
    --primary-color: #f8c723;
    --bg-grey: #f5f7f6;
    --bg-black: #201f1d;
    --bg-white: #ffffff;
    --text-color: #212529;
    --text-color-white: #ffffff;
    --font-color: #676767;
    --heading-color: #343a40;
    --border-color: #dee2e6;
}

.services {

    padding-bottom: 7%;

    .services .card {
        position: relative;
        z-index: 1;
        border-top: 3px solid var(--primary-color);
    }

    .services i {
        color: var(--primary-color);
    }

    .card>.anim-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: var(--primary-color);
        transition: height 0.3s ease;
        border-radius: 12px;
    }

    .card:hover .anim-layer {
        height: 100%;
        border-radius: 12px;
    }

    .card:hover h2,
    .card:hover .icon-hidden-card,
    .card:hover p {
        position: relative;
        color: var(--text-color-white);
    }

    .card .icon-hidden-car {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .card {
        background-color: var(--bg-white);
        height: 350px;
        text-align: center;
        padding: 37px;
        background-color: #f5f5f5b2;
        border: none;
        outline: none;
        border-radius: 12px;

        h2 {
            color: #07151b;
            font-size: 21px;
        }

        p {
            font-size: 1rem;
            text-align: center;
            color: #7c878e;
        }

        .icon-hidden-card {
            font-size: 40px;
            color: #8c8c8c;
            padding-bottom: 1.75rem;
        }
    }

    .card:hover i {
        color: var(--primary-color);
    }
}
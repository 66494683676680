::marker {
    color: #f8c723;
    font-size: 27px;
}
.left-right-card-ncn {
    color: #8c8c8c;
    margin-top: 17px;
}
.left-right-card-ncn li {
    margin-bottom: 17px;
}
 
.h3-rlcard-nyscn{
    color: #000000f2;
    font-size: 1.6rem;
    text-transform: uppercase;
}
.card-img-overlay{
    
}